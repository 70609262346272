@import './styles/variables.scss';
@import './styles/rc-switch.scss';
@import './styles/theme/index.scss';
@import './styles/theme/light.scss';

html {
  font-size: 62.5%; /* Now 10px = 1rem! */
  -webkit-text-size-adjust: 100%;
}

@supports (-webkit-touch-callout: none) {
  html {
    font-size: 11px;
  }
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
.AppWrapper,
.App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  position: fixed;
  font-size: 16px; /* px fallback */
  font-size: 1.6rem; /* default font-size for document */
  line-height: 1.5; /* a nice line-height */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  overscroll-behavior: none !important;
  box-sizing: border-box;
}

#root {
  background-color: $color-gray-0;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    calc(env(safe-area-inset-bottom) - 20px) env(safe-area-inset-left);
}

.landscape #root {
  padding: 0 !important;
}

.AppWrapper {
  position: relative;
}

.App {
  transition: transform $transition-duration;
  display: flex;
  flex-direction: column;
  color: $color-gray-14;
  background-color: $color-gray-1;

  > section {
    flex-grow: 1;
    background-color: $color-gray-0;
    height: calc(100% - #{$footer-height});
  }

  &.showInstallPrompt {
    > section {
      height: calc(100% - #{$footer-height} - #{$install-prompt-height});
    }
  }
}

.App,
.Screen {
  &.behind {
    transform: scale(0.9);
  }

  &:not(.behind) {
    transition-delay: $transition-duration;
  }
}

.Screen {
  overflow: hidden;
}

.logotype {
  svg .cls-1 {
    fill: $color-gray-15;
  }
}

.tagline {
  font-family: $font-family-tagline;
  text-transform: lowercase;
  color: $color-brand-red;
}

h1 {
  font-size: $font-size-h1;
  margin: 1rem 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
p,
ol,
li {
  color: $color-gray-14;
}

button {
  background-color: transparent;
  outline: 0;
  border: 0;

  i {
    color: $color-gray-14;
    font-size: $font-size-button-icon;
  }

  .text {
    font-weight: normal;
    font-size: $font-size-regular;
  }
}

.text-center {
  text-align: center;
}

.sr-only {
  width: 0;
  height: 0;
  visibility: hidden;
}

a {
  color: $color-primary;
}

footer {
  position: relative;
  width: 100%;
  height: $footer-height;

  nav {
    height: 100%;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    list-style-type: none;
    background-color: $color-gray-1;

    li {
      flex-grow: 1;
      width: 25%;
      height: 100%;

      &.active {
        pointer-events: none;

        button {
          i,
          .text {
            color: $color-primary !important;
          }
        }
      }

      button {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;

        i {
          font-size: 2.4rem;

          + .text {
            margin-top: 0.5rem;
          }
        }

        i,
        .text {
          transition: color $transition-duration;
          color: $color-gray-5;
        }

        .text {
          font-size: $font-size-small;
        }
      }
    }
  }
}

.Screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  header {
    position: relative;
    width: 100%;
    height: 4rem;
    background-color: $color-gray-1;

    nav {
      position: relative;
      height: 100%;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      height: 100%;
      list-style-type: none;

      li {
        $hitAreaCirc: 10rem;

        height: 100%;
        min-width: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn-icon-only {
          position: relative;
          padding: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .btn-link {
          font-weight: normal;
          text-align: right;
          padding-right: 0.5rem;
          color: $color-gray-15;
        }

        &:nth-child(1) {
          .btn-icon-only {
            justify-content: flex-start;
            padding-left: 1.5rem;
            @include cornerButton(tl);
          }
        }

        &:nth-child(2) {
          flex-grow: 1;
          text-align: center;
          font-size: 1.4rem;
          color: $color-gray-9;
        }

        &:nth-child(3) {
          .btn-icon-only {
            justify-content: flex-end;
            padding-right: 1.5rem;
            @include cornerButton(tr);
          }
        }
      }
    }
  }

  > section {
    width: 100%;
    height: calc(100% - #{$footer-height});
    flex-grow: 1;

    > .wrapper {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: $color-gray-0;
    }

    .emptyData,
    .emptyList {
      width: 100%;
      height: 100%;
      color: $color-gray-6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 6rem;
      text-align: center;

      h2 {
        font-size: $font-size-h2;
        margin: 0 0 2rem 0;
        padding: 0;
        line-height: 3rem;
      }

      p {
        font-size: $font-size-regular;
        margin: 0;
        padding: 0;
      }
    }
  }
}

form {
  .rc-switch {
    margin: 1rem 0;
  }

  fieldset {
    position: relative;
    outline: none;
    border: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    &:not(:first-child) {
      margin-top: 2rem;
    }

    label {
      font-size: $font-size-regular;
      margin-bottom: 0.5rem;
      color: $color-gray-15;
    }

    .notes {
      font-size: $font-size-small;
      opacity: $opacity-dimmed;
      margin-top: 0.5rem;
    }

    &.error {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }

    &:not(.inline) + fieldset.inline {
      margin-top: 1rem;
    }

    &.inline {
      $label-width: 10rem;

      display: block;
      position: relative;
      border-top: 1px solid $color-gray-2;
      padding-left: $label-width;
      margin-top: 0;

      &:last-child {
        border-bottom: 1px solid $color-gray-2;
      }

      label {
        position: absolute;
        top: 0;
        left: 0;
        width: $label-width;
        text-align: left;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin: 0;
        font-size: $font-size-regular;
      }

      .form-control {
        width: 100%;
        flex-grow: 1;
        text-align: left;
        background-color: transparent;
        padding: 1rem 0;
        border-radius: 0;
        margin: 0;
        font-size: $font-size-regular;
      }

      textarea {
        resize: none;
      }

      .notes {
        text-align: left;
      }
    }
  }
}

.btn,
.form-control {
  border: none;
  border-radius: $border-radius;
  outline: 0;
  line-height: 2.1rem;
}

textarea {
  font-family: $font-family;
  font-size: $font-size-regular;
}

.form-control {
  transition: background-color $transition-duration;
  padding: 0.8rem;
  font-family: $font-family;
  font-size: $font-size-input;
  color: $color-gray-15;
  background-color: rgba(255, 255, 255, 0.2);

  &:focus {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.btn {
  outline: none;
  font-size: 1.2rem;
  font-weight: bold;

  &:not(.btn-link) {
    padding: 0.7rem 1.5rem;
  }

  &.btn-shadow {
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.25);
  }

  &.btn-link {
    padding: 0;
  }

  &.btn-rounded {
    border-radius: 3rem;
  }

  &.btn-dimmed {
    opacity: $opacity-dimmed;
  }

  &.btn-default {
    &:not(.btn-link) {
      &:not(.btn-outline) {
        background-color: $color-default;
        color: $color-gray-1;
      }

      &.btn-outline {
        color: $color-default;
        border: 1px solid $color-default;
        background-color: transparent;
      }
    }

    &.btn-link {
      background-color: transparent;
      color: $color-default;
      font-size: 16px;
    }
  }

  &.btn-primary {
    &:not(.btn-link) {
      &:not(.btn-outline) {
        background-color: $color-primary;
        color: $color-gray-1;
      }

      &.btn-outline {
        color: $color-primary;
        border: 1px solid $color-primary;
        background-color: transparent;
      }
    }

    &.btn-link {
      background-color: transparent;
      color: $color-primary;
      font-size: 16px;
    }
  }

  &.btn-danger {
    &:not(.btn-link) {
      &:not(.btn-outline) {
        background-color: $color-danger;
        color: $color-gray-15;
      }

      &.btn-outline {
        color: $color-danger;
        border: 1px solid $color-danger;
        background-color: transparent;
      }
    }

    &.btn-link {
      background-color: transparent;
      color: $color-danger;
      font-size: 16px;
    }
  }

  &.btn-icon-only {
    background-color: transparent;
    outline: none !important;

    i {
      font-size: $font-size-button-icon;

      &.icon-big {
        margin-top: 0.3rem;
        font-size: $font-size-button-icon-big;
      }
    }

    &.btn-corner-tl {
      @include cornerButton(tl);
    }
    &.btn-corner-tr {
      @include cornerButton(tr);
    }
    &.btn-corner-bl {
      @include cornerButton(bl);
    }
    &.btn-corner-br {
      @include cornerButton(br);
    }
  }

  &.disabled,
  &[disabled] {
    opacity: 0.25;
    color: $color-default;

    i {
      color: $color-default !important;
    }
  }

  .text + i,
  i + .text {
    margin-left: 0.5rem;
  }

  .rating-score + .text {
    margin-left: 0.75rem;
  }

  .text + .badge {
    margin-left: 0.5rem;
    font-size: 1.1rem;
    padding: 0 0.5rem;
    height: 1.8rem;
  }

  .LoadingIndicator {
    height: 2rem;
    transform: scale(0.35);
    color: $color-gray-15;
  }
}

.toggleButton {
  background-color: $color-gray-3;
  border-radius: $border-radius;

  button.btn {
    position: relative;
    width: 11rem;
    height: 2.6rem;
    padding: 0;

    &.btn.btn-default:not(.btn-link):not(.btn-outline) {
      background-color: transparent;
      color: $color-gray-15;
      font-weight: normal;

      span {
        position: relative;
      }
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &::before {
        transition: transform $transition-duration;
        content: '';
        position: absolute;
        width: 10.6rem;
        height: 2.2rem;
        top: 0.2rem;
        left: 0.2rem;
        border-radius: $border-radius * 0.8;
        background-color: $color-gray-6;
      }

      &.active {
        pointer-events: none;
      }

      &:not(.active) {
        &::before {
          transform: translateX(11rem);
        }
      }
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

p i {
  padding: 0 0.3rem;
}

.badge {
  font-size: $font-size-small;
  background-color: $color-gray-6;
  border-radius: 10rem;
  padding: 0 1rem;
  display: inline-block;

  &.color-danger {
    color: $color-gray-15 !important;
    background-color: $color-danger;
  }
}

.react-code-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.SelectControl {
  > [class*='control'] {
    min-height: 34px;
    background-color: transparent;
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
  }

  > [class*='control'] > div:first-child {
    padding-top: 0;
    padding-bottom: 0;

    :not([class*='placeholder']) {
      color: $color-gray-15;
    }
  }

  [class*='indicatorContainer'] {
    padding-top: 5px;
    padding-bottom: 3px;
    color: $color-gray-7 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;

    svg {
      display: none;
    }

    &::before {
      font-family: 'Font Awesome 5 Pro';
      content: '\f00d';
    }
  }

  [class*='indicatorSeparator'] {
    opacity: 0.3;

    + [class*='indicatorContainer'] {
      width: 4rem;

      &::before {
        content: '\f078';
      }
    }
  }

  [class*='menu'] {
    margin-top: 1px;
    background-color: $color-gray-3;
    box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.5);
  }

  [class*='MenuList'] {
    max-height: 20rem;
  }

  [class*='ValueContainer'] {
    overflow: visible;
  }
}

fieldset.inline {
  .SelectControl.form-control {
    padding: 0;

    > [class*='control'] {
      min-height: 41px;
    }

    > [class*='control'] > div:first-child {
      padding-left: 0;

      > div:first-child {
        margin-left: 0;
      }
    }

    [class*='indicatorSeparator'] {
      display: none;
    }

    [class*='indicatorContainer'] {
      width: 3rem;
      padding-left: 0;
      padding-right: 0.5rem;
      justify-content: flex-end;

      + [class*='indicatorSeparator'] {
        + [class*='indicatorContainer'] {
          display: none;
        }
      }
    }
  }
}

.wifiWarning {
  font-size: $font-size-small;
  text-align: center;
  margin: 0;
  padding: 0.5rem 1.5rem;
  background-color: $color-warning;
  color: $color-gray-0;
}

.attention {
  &::after {
    $circ: 0.6rem;
    content: '';
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    width: $circ;
    height: $circ;
    border-radius: 50%;
    background-color: $color-primary;
    animation: 0.8s infinite alternate attention_pulse;

    @keyframes attention_pulse {
      from {
        opacity: 0.5;
        transform: scale(1);
      }
      to {
        opacity: 1;
        transform: scale(1.3);
      }
    }
  }
}

@media screen and (orientation: landscape) {
  #root {
    padding: 0 0 env(safe-area-inset-bottom) 0;
  }

  .Screen header {
    padding-left: calc(env(safe-area-inset-right) - 30px);
    padding-right: calc(env(safe-area-inset-right) - 30px);
  }
}

.Toastify {
  .Toastify__toast-container {
    padding: 0rem;
  }

  .Toastify__toast {
    background: $color-brand-blue;
    font-size: $font-size-regular;
    box-shadow: 0 0 4rem rgba(0, 0, 0, 1);
    padding-left: 2rem;
  }

  .Toastify__close-button {
    width: 3rem;
    height: 3rem;

    svg {
      transform: scale(1.2);
    }
  }
}
