@import '../../styles/variables.scss';

.StudentDetailsScreen {
  transition: transform $transition-duration;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color-gray-0;
  z-index: 5;

  &:not(.visible) {
    transform: translateY(110%);
    pointer-events: none;
  }

  .wrapper {
    padding-top: 3rem;
  }

  h2 {
    padding: 0 2rem;
    margin: 0 0 1rem 0;
  }

  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    fieldset {
      width: calc(100% - 4rem) !important;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    .form-control {
      width: 100%;
      text-align: center;
    }

    textarea {
      width: 100% !important;
      text-align: left !important;
    }
  }

  .SelectControl {
    [class*="-Group"]:not(:first-child) {
      border-top: 1px solid $color-gray-4;
    }

    .badge {
      background-color: $color-gray-5;
      border-radius: 2em;
      color: $color-gray-10;
      display: inline-block;
      font-size: $font-size-smaller;
      line-height: $font-size-smaller;
      padding: 0.25rem 0.75rem;
    }

    [class*="-singleValue"],
    [class*="-menu"] [class*="-option"] {
      display: flex;
      align-items: center;

      .AvatarIcon {
        flex-shrink: 0;
        width: 2.8rem;
        height: 2.8rem;
        margin-right: 1rem;

        .initial {
          font-size: 1em;
        }
      }

      .name {
        flex-grow: 1;
        color: $color-gray-15;
      }
    }
  }
}