@import '../variables.scss';

.AppWrapper.theme-light {
  .App {
    color: $color-gray-1;
    background-color: $color-gray-14;

    > section {
      > .wrapper {
        background-color: $color-gray-15;
      }
    }
  }

  .App > section,
  .Screen > section > .wrapper {
    background-color: $color-gray-15;
  }

  h1, h2, h3, h4, h5, p, ol, li {
    color: $color-gray-1;
  }

  .Screen header {
    background-color: $color-gray-14;
  }

  .Screen header ul li:nth-child(2) {
    color: $color-gray-5;
  }

  .badge {
    background-color: $color-gray-12;
  }

  button i {
    color: $color-gray-1;
  }

  form fieldset label {
    color: $color-gray-0;
  }

  .form-control {
    color: $color-gray-0;
  }

  form fieldset:not(.inline) {
    .form-control {
      background-color: rgba($color-gray-0, 0.2);
    }
  }

  .color-default {
    color: $color-gray-0 !important;
  }

  .SelectControl > [class*="control"] > div:first-child :not([class*="placeholder"]) {
    color: $color-gray-0;
  }

  .SelectControl [class*="menu"] {
    background-color: $color-gray-14;
  }

  .SelectControl [class*="menu"] [class*="-option"] {
    background-color: $color-gray-13;
  }
  
  .SendVideoScreen .SelectControl [class*="-menu"] [class*="-option"] .name {
    color: $color-gray-0;
  }

  form fieldset.inline {
    border-color: $color-gray-13;
  }

  .rc-switch {
    border-color: $color-gray-11;
    background-color: $color-gray-11;

    &-checked {
      border: 1px solid $color-primary;
      background-color: $color-primary;
    }
  }

  .toggleButton {
    background-color: $color-gray-12;
  }

  .toggleButton button.btn.btn.btn-default:not(.btn-link):not(.btn-outline) {
    color: $color-gray-0;
  }

  .toggleButton button.btn:first-child::before {
    background-color: $color-gray-9;
  }

  footer ul {
    background-color: $color-gray-14;
  }

  footer ul li:not(.active) button i {
    color: $color-gray-10;
  }

  .ProfileScreen > section .wrapper .view .code {
    background-color: $color-gray-13;
  }

  .ContactList ul li .btn.btn-icon-only {
    background-color: $color-primary;
  }

  .ContactList ul li {
    border-top-color: $color-gray-12;
  }
  
  .SendVideoScreen .videoInputContainer {
    background-color: rgba($color-gray-0, 0.2);

    .video-js {
      background-color: rgba($color-gray-15, 0);
    }
  }

  .SendVideoScreen .videoInput i {
    color: $color-gray-0;
  }

  .HomeScreenGuide {
    background-color: $color-gray-15;

    h2, p {
      color: $color-gray-0;
    }

    ol {
      color: $color-gray-0;
    }
  }

  .FriendRequestScreen .foundUser {
    background-color: $color-gray-13;
  }

  .FriendRequestScreen .foundUser .btn[disabled] {
    background-color: $color-gray-12;
  }

  .VideoList {
    ul li {
      border-top-color: $color-gray-12;

      .SwipeOptionWrapper .content {
        background-color: $color-gray-15;
      }
    }

    .info .title {
      color: $color-gray-4;
    }
  }

  .VideoScreen {
    background: $color-gray-15;

    .annotationInfos li {
      border-top-color: $color-gray-11;
    }
    
    &.infoContainer .infoContent,
    .infoContainer .infoContent {
      background: $color-gray-14;
    }

    .info .primary .AvatarIcon .icon i {
      color: $color-gray-0;
    }
  }

  .VideoPlayer {
    .video-js {
      background-color: $color-gray-15;

      .vjs-control-bar {
        background-color: $color-gray-14;
      }

      .vjs-play-control,
      .vjs-mute-control,
      .vjs-progress-holder .vjs-play-progress {
        color: $color-gray-0;
      }

      .vjs-play-progress.vjs-slider-bar {
        background-color: $color-gray-5;
      }

      .vjs-progress-holder .vjs-load-progress div {
        background-color: $color-gray-10;
      }
    }
  }

  .AnimatedPlayButton .iconContainer i {
    color: $color-gray-15;
  }

  .VideoScreen .annotationIndicators .annotationIndicator .text,
  .VideoScreen .annotationInfos li .index .text {
    color: $color-gray-15;
  }

  .VideoScreen .annotationThumbnail {
    background-color: $color-gray-13;

    i {
      color: $color-gray-9;
    }
  }

  .VideoThumbnail {
    background-color: $color-gray-15;
  }

  .SimpleVideoEditor {
    .timeline .time {
      color: $color-gray-0;
    }

    .audioRecorder,
    .controlsContainer,
    .toolsContainer {
      background: $color-gray-15;
    }

    .toolsContainer .tools .btn i {
      color: $color-gray-1;
    }

    .controlsContainer {
      .stepBackwardButton::before {
        background: linear-gradient(90deg, rgba($color-gray-15, 1) 40%, rgba($color-gray-15, 0) 100%);
      }

      .stepForwardButton::before {
        background: linear-gradient(90deg, rgba($color-gray-15, 0) 0%, rgba($color-gray-15, 1) 60%);
      }
    }

    .annotations .annotation {
      background: $color-gray-0;

      &.selected {
        background: $color-primary;
      }
    }

    .DrawingScreen .tools {
      background: $color-gray-15;

      &::before {
        background-image: linear-gradient(90deg, rgba($color-gray-15, 1) 0%, rgba($color-gray-15, 0) 100%);
      }

      &::after {
        background-image: linear-gradient(90deg, rgba($color-gray-15, 0) 0%, rgba($color-gray-15, 1) 100%);
      }

      .brush {
        background: $color-gray-10;
      }
    }
  }
}