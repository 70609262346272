@import '../../styles/variables.scss';

.TargetIndicator {
  $color: rgba($color-brand-red, 0.9);
  $color2: rgba($color-gray-15, 0.9);
  $circ: 5rem;
  $animDuration: 3s;

  box-sizing: border-box;
  height: $circ;
  width: $circ;
  position: relative;
  
  * {
    box-sizing: border-box;
  }

  .circle {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    animation: radar-spinner-animation $animDuration infinite;

    &:nth-child(1),
    &:nth-child(3) {
      padding: calc(#{$circ} * 5 * 2 * 0 / 110);
      animation-delay: $animDuration * 0.1;
    }
  
    &:nth-child(2),
    &:nth-child(4) {
      padding: calc(#{$circ} * 5 * 2 * 1 / 110);
      animation-delay: 0;
    }
  }

  .circle-inner,
  .circle-inner-container {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: calc(#{$circ} * 5 / 110) solid transparent;
  }

  .circle-inner {
    border-left-color: $color;
    border-right-color: $color;
  }

  .circle:nth-child(1),
  .circle:nth-child(2) {
    .circle-inner {
      border-left-color: $color2;
      border-right-color: $color2;
      border-width: calc(#{$circ} * 5 / 70);
    }
  }

  @keyframes radar-spinner-animation {
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

.SimpleTargetIndicator {
  $color: rgba($color-brand-red, 0.9);

  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::after {
    $circ: 0.75rem;
    width: $circ;
    height: $circ;
    background: $color;
    border-radius: 50%;
    border: 1px solid $color-gray-15;
    margin-top: $circ * -0.5;
    margin-left: $circ * -0.5;
  }

  &::before {
    width: 3rem;
    border-bottom: 1px solid $color;
    border-top: 1px solid $color-gray-15;
  }
}