@import '../variables.scss';

.AppWrapper.useTheme {
  form fieldset label,
  .color-default,
  footer ul li:not(.active) button i {
    transition: color $transition-duration;
  }

  .App > .section > .wrapper,
  .Screen > section > .wrapper,
  .Screen header,
  footer ul,
  .ProfileScreen > section .wrapper .view .code,
  .badge {
    transition: background-color $transition-duration;
  }

  form fieldset.inline {
    transition: border-color $transition-duration;
  }

  .rc-switch {
    transition: border-color $transition-duration, background-color $transition-duration;
  }
}